import { cn } from '~/utils/misc.ts'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'

const emptyVariants = cva(
  'px-6 py-4 text-center text-body-16 font-medium leading-5',
  {
    variants: {
      variant: {
        light: 'bg-background-default-dark text-text-muted-dark',
        dark: 'bg-background-default text-text-muted',
      },
    },
    defaultVariants: {
      variant: 'dark',
    },
  },
)

export type EmptyProps = VariantProps<typeof emptyVariants> & {
  message: string
  className?: string
}

export function Empty(props: EmptyProps) {
  const { className, variant, message } = props
  return (
    <div
      role="alert"
      data-testid="empty-contnet-message"
      className={cn(emptyVariants({ variant, className }))}
    >
      {message}
    </div>
  )
}
