import { cn } from '~/utils/misc.ts'
import { Image } from '~/components/image.tsx'
import { buttonVariants } from '@rouvydev/web-components'
import { useTranslation } from 'react-i18next'
import { NormalizedLink } from '~/components/normalized-link.tsx'

export type Article = {
  id: string
  title: string
  link: string
  perex: string
  dateDisplay?: string
  author?: {
    name: string
    slug: string
  }
  cover: {
    alt: string | null
    url: string | null
    formats: {} | null | undefined
  }
}

function Info({
  dateDisplay,
  author,
}: {
  dateDisplay?: string
  author?: {
    name: string
    slug: string
  }
}) {
  return (
    <p className="text-body-16 text-text-muted">
      {dateDisplay} {author ? '\u2013' : null}{' '}
      {author ? (
        <NormalizedLink to={`/blog/author/${author.slug}`}>
          {author.name}
        </NormalizedLink>
      ) : null}
    </p>
  )
}

export function Articles({
  articles,
  isMainArticle = true,
}: {
  isMainArticle?: boolean
  articles: Article[]
}) {
  const { t } = useTranslation()

  const mainArticle = isMainArticle ? articles[0] : null
  const remainingArticles = isMainArticle ? articles.slice(1) : articles

  return (
    <div className="flex flex-col gap-10">
      {mainArticle && (
        <div className="bg-background-default">
          {mainArticle.cover?.url && (
            <div className="relative aspect-square max-h-[611px] md:aspect-video lg:shrink-0">
              <NormalizedLink to={mainArticle.link}>
                <Image
                  src={mainArticle.cover.url}
                  alt={mainArticle.cover.alt ?? ''}
                  width={1088}
                  height={611}
                  className="h-full w-full object-cover object-center"
                  loading="eager"
                  skipSmallVariants
                  formats={mainArticle.cover.formats}
                />
              </NormalizedLink>
            </div>
          )}
          <div className="flex flex-col gap-6 p-8">
            <Info
              dateDisplay={mainArticle.dateDisplay}
              author={mainArticle.author}
            />

            <h3 className="font-display text-display-40 font-extrabold uppercase italic leading-10 text-text-default">
              <NormalizedLink to={mainArticle.link}>
                {mainArticle.title}
              </NormalizedLink>
            </h3>
            <p className="text-body-20 text-text-muted">{mainArticle.perex}</p>
            <p>
              <NormalizedLink
                className={buttonVariants({
                  size: 'medium',
                  variant: 'alternative',
                })}
                to={mainArticle.link}
              >
                {t('read_article_link')}
              </NormalizedLink>
            </p>
          </div>
        </div>
      )}

      {remainingArticles.map(article => (
        <article
          key={article.id}
          className={cn(
            'relative isolate flex flex-col bg-background-default lg:flex-row ',
          )}
        >
          <div className="relative aspect-square md:aspect-video lg:w-[416px] lg:shrink-0">
            {article.cover?.url && (
              <NormalizedLink to={article.link}>
                <Image
                  src={article.cover.url}
                  alt={article.cover.alt ?? ''}
                  width={1088}
                  height={611}
                  className="h-full w-full object-cover object-center"
                />
              </NormalizedLink>
            )}
          </div>
          <div className="flex flex-col gap-6 overflow-hidden p-4 lg:p-8">
            <Info dateDisplay={article.dateDisplay} author={article.author} />

            <h3 className="line-clamp-2 font-display text-display-40 font-extrabold uppercase italic leading-10 text-text-default">
              <NormalizedLink to={article.link}>{article.title}</NormalizedLink>
            </h3>
            <p className="line-clamp-4  text-body-20 text-text-muted">
              {article.perex}
            </p>
            <p>
              <NormalizedLink
                className={buttonVariants({
                  size: 'medium',
                  variant: 'alternative',
                })}
                to={article.link}
              >
                {t('read_article_link')}
              </NormalizedLink>
            </p>
          </div>
        </article>
      ))}
    </div>
  )
}
